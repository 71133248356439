import { Component } from "@angular/core";
import { ActivatedRoute, Route, Router } from "@angular/router";

@Component({
  selector: "app-book-now",
  template: `<div class="book-now-circle" (click)="navigate()">
    <div>Book<br />Now!</div>
  </div>`,
  // templateUrl: './book-now.component.html',
  styleUrls: ["./book-now.component.scss"],
})
export class BookNowComponent {
  asUser: number;
  constructor(private route: ActivatedRoute, private router: Router) {
    this.asUser = this.route.snapshot.data?.asUser?.id;
  }

  navigate() {
    const url = this.asUser ? `/as-user/${this.asUser}/book` : `/book`;
    this.router.navigateByUrl(url);
  }
}

<div id="UserSettingsComponent">
  <div class="container-fluid main">
    <div class="row">
      <div class="col-md-12 mb-4">
        <h1>My Account</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="user-settings-main hms-radius">
          <form [formGroup]="userForm">
            <div class="row">
              <div class="col-md-12 flex-center-between">
                <h3 class="form-heading">Account information</h3>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <app-input controlName="first_name" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <app-input controlName="last_name" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <app-email-input controlName="email" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <app-phone-input controlName="phone" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <app-input controlName="address" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-xs-12 col-md-4">
                <app-input name="city" controlName="city" />
              </div>
              <div class="col-xs-12 col-md-4">
                <app-input controlName="state" />
              </div>
              <div class="col-xs-12 col-md-4">
                <app-integer-input controlName="zip" />
              </div>
            </div>
            <app-submit-button
              (clicked)="updateUser(userForm.value)"
              text="SAVE CHANGES"
              classNames="btn btn-secondary"
              [loading]="loading && whichLoading === 'save'"
              [disabled]="!userForm.valid"
              disabledStyle
            />
          </form>
        </div>
      </div>

      <div class="col-md-4">
        <div class="user-settings-main hms-radius">

          <!-- User Change Password Form -->
          <form [formGroup]="passwordForm">
            <div *ngIf="!asUser; else resetPassword">
              <div class="row">
                <div class="col-md-12 flex-center-between">
                  <h3 class="form-heading">Password</h3>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <app-password-input controlName="currentPassword" allowShow />
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <app-password-input controlName="newPassword" allowShow />
                </div>
              </div>
              <app-submit-button
                (clicked)="updatePassword(passwordForm.value)"
                text="UPDATE PASSWORD"
                classNames="btn btn-secondary"
                [loading]="loading && whichLoading === 'password'"
                [disabled]="!userForm.valid"
              />
            </div>
            
            <!-- Admin Password Options -->
            <ng-template #resetPassword>
              <div class="row">
                <div class="col-md-12">
                  <h4 class="form-heading">Send Reset Password Email</h4>
                </div>
                <div class="col-md-12">
                  <button
                    (click)="confirmResetPassword()"
                    class="btn btn-secondary w-100 mt-2"
                    [loading]="loading && whichLoading === 'password'"
                    [disabled]="!userForm.valid"
                  >
                    Reset Password
                  </button>
                  <hr/>
                  <h4 class="form-heading">Change Password</h4>
                  <div class="row form-group mt-10">
                    <div class="col-md-12">
                      <app-password-input class="mt-2" controlName="newPassword" allowShow />
                    </div>
                  </div>
                  <app-submit-button
                    (clicked)="updatePassword(passwordForm.value)"
                    text="CHANGE PASSWORD"
                    classNames="btn btn-secondary w-100"
                    [loading]="loading && whichLoading === 'password'"
                  />
                </div>
              </div>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Inject } from "@angular/core";
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  RouterState,
  ActivatedRoute,
} from "@angular/router";
import { AuthResponse, UserService } from "./services/user.service";
import { routeFadeAnimation } from "./animations";
import { LocationService } from "@services/location.service";
import { distinctUntilChanged, filter, map } from "rxjs/operators";
import { Title } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
import { AppService } from "@services/app.service";
import { App } from "@models/app";

declare const gtag: any;

@Component({
  selector: "app-root",
  template: ` <div [@routerAnimation]="view" class="position-relative">
      <router-outlet />
    </div>
    <app-toasts />
    <app-navigation-spinner></app-navigation-spinner>
    <div class="loading-overlay" [class.hidden]="!loading">
      <img [src]="imgSource" />
      <div class="spinner-border mt-4" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>`,
  styleUrls: ["./app.component.scss"],
  animations: [routeFadeAnimation],
})
export class AppComponent {
  title = "app";
  imgSource: string;
  public loading = true;

  constructor(
    private router: Router,
    private app: AppService,
    private titleService: Title,
    private user: UserService,
    private location: LocationService,
    @Inject(DOCUMENT) private document: Document
  ) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        distinctUntilChanged((x: NavigationStart, y: NavigationStart) => {
          const left =
            x && x.url && x.url.includes("?") ? x.url.split("?")[0] : x.url;
          const right =
            y && y.url && y.url.includes("?") ? y.url.split("?")[0] : y.url;
          return left === right;
        })
      )
      .subscribe((event) => window.scrollTo({ top: 0, behavior: "smooth" }));

    this.imgSource = "../assets/images/HMS-02.png";

    if (this.user?.token) this.loadGoogleAnalytics().subscribe((res) => {});
  }

  get view() {
    return !this.router.url.includes("/book")
      ? this.router.url.split("?")[0]
      : null;
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      const title = this.getTitle(
        this.router.routerState,
        this.router.routerState.root
      ).join("-");
      this.titleService.setTitle(title);

      if (window.gtag) {
        gtag("event", "page_view", {
          page_title: title,
          page_path: event.urlAfterRedirects,
          page_location: this.document.location.href,
        });
      }
    }
    // Set loading state to false in both of the below events to hide the
    // spinner in case a request fails
    if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data["title"]) {
      data.push(parent.snapshot.data["title"]);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }

  loadGoogleAnalytics(authResponse?: AuthResponse) {
    return this.app.get().pipe(
      map((app: App) => {
        const trackingID = app.advertising_link;
        if (trackingID) {
          document.getElementById("google-1")?.remove();
          document.getElementById("google-2")?.remove();

          const gaScript = document.createElement("script");
          gaScript.setAttribute("async", "true");
          gaScript.setAttribute(
            "src",
            `https://www.googletagmanager.com/gtag/js?id=${trackingID}`
          );
          gaScript.setAttribute("id", `google-1`);

          const gaScript2 = document.createElement("script");
          gaScript2.setAttribute("id", `google-2`);

          gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\');`;

          document.documentElement.firstChild.appendChild(gaScript);
          document.documentElement.firstChild.appendChild(gaScript2);
        }
        return authResponse;
      })
    );
  }
}

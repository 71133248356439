import { map, switchMap, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpService } from "./api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "../models/user";
import { Person } from "../models/person";
import { DateTime } from "luxon";
import { BehaviorSubject, Observable } from "rxjs";
import { ConfirmComponent } from "@shared/confirm/confirm.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { NotifyService } from "./notify.service";
import { HolidayMagicService } from "./holiday-magic.service";

export class AuthResponse {
  user: User;
  token: string;
  person: Person;
  errorMessage?: any;
}

export class ChangePasswordRequest {
  id: Number;
  email: string;
  oldPassword: string;
  newPassword: string;
}

export class ResetPasswordRequest {
  token: string;
  newPassword: string;
}

@Injectable({ providedIn: "root" })
export class UserService {
  authResponse: AuthResponse;
  public user$ = new BehaviorSubject(new User());

  constructor(
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private magic: HolidayMagicService,
    private notify: NotifyService,
    private modalService: BsModalService
  ) {}

  /**
   * Get a user from the server by their id.
   *
   * @param {number} id
   * @returns {Observable<User>}
   */
  fetchUser(id: number): Observable<User> {
    const query = {
      get: [
        "*",
        {
          get: ["id", "date"],
          table: "Appointment",
          where: { on: [{ Appointment: "user_id", User: "id" }] },
          sort: { date: "desc" },
          as: "appointments",
          group: ["id"],
          join: [
            {
              get: ["*"],
              table: "Order",
              returnType: "object",
              joinType: "left join",
              on: [{ Order: "appointment_id", Appointment: "id" }],
              as: "order",
            },
            {
              get: ["id", "sm_folder_obj"],
              table: "Folder",
              joinType: "left join",
              returnType: "object",
              on: [{ Appointment: "folder_id", Folder: "id" }],
              as: "folder",
            },
          ],
        },
      ],
      join: [
        {
          table: "Person",
          joinType: "inner join",
          returnType: "object",
          on: [{ User: "id", Person: "user_id" }],
          as: "person",
        },
      ],
      where: { id },
      limit: 1,
    };
    return this.http
      .post("/user/get", query)
      .pipe(map((res) => new User(res.data?.[0])));
  }

  /**
   * Find the user from internal storage or localStorage.
   *
   * @returns {User}
   */
  get user(): User | null {
    try {
      if (this.authResponse && this.authResponse.user) {
        return this.authResponse.user as User;
      }

      let u = <any>atob(localStorage.getItem("user"));
      if (u) {
        u = JSON.parse(u);

        if (typeof u === "string") {
          u = JSON.parse(u);
        }

        return u as User;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  /**
   * Returns boolean if a user has the role being asked for.
   *
   * @param role
   */
  can(role): boolean {
    const user = <User>this.user;

    return (
      user?.userroles
        .map((userRole) => userRole.roles.description)
        .indexOf(role) !== -1
    );
  }

  /**
   * get token.
   *
   */
  get token(): string {
    return localStorage.getItem("token");
  }

  /**
   * Store the token and user information.
   *
   * @param authResp
   */
  set userAndToken(authResp: AuthResponse) {
    authResp.user.role = authResp?.user?.userroles?.[0]?.roles;
    delete authResp?.user?.userroles?.[0]?.roles;
    this.authResponse = authResp;
    this.storeUser(btoa(JSON.stringify(authResp.user)));
    localStorage.setItem("token", authResp.token);
  }

  /**
   * Store the token information.
   *
   * @param user
   */
  storeUser(user: string): void {
    const updatedUser = JSON.parse(atob(user));
    this.user$.next(updatedUser);
    localStorage.setItem("user", user);
  }

  /**
   * Log a user in and store the data locally.
   *
   * @param email
   * @param {string} password
   * @returns {Observable<any>}
   */
  login(email: string, password: string): Observable<AuthResponse | void> {
    return this.http.post("/user/login", { email, password }).pipe(
      tap((authResp: AuthResponse) => {
        if (!authResp.errorMessage) this.userAndToken = authResp;
      })
    );
  }

  /**
   * Method for loggin a user out.
   *
   * @param {string} url
   */
  logout(url: string = "/login") {
    // To log out, just remove the token
    // from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.user$.next(new User());

    // Send the user back to the public deals page after logout
    this.router.navigateByUrl(url);

    const ga = document.getElementById("google-1");
    if (ga) window.location.reload();
  }

  /**
   * Create a new account.
   *
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} email
   * @param {string} phone
   * @param {string} password
   * @return {Promise<never | never | any>}
   */
  createAccount(
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    password: string
  ): Observable<any> {
    return this.http
      .post("/user/create-secure", { email, password })
      .pipe(
        switchMap((user) =>
          this.createPerson(user?.data?.data, firstName, lastName, phone, email, password)
        )
      );
  }

  registerNewCustomer(
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    password: string
  ): Observable<any> {
    return this.http
      .post("/person/register-new-customer", { email, password })
      .pipe(
        switchMap(({ data }) =>
          this.createPerson(data, firstName, lastName, phone, email, password)
        )
      );
  }

  /**
   * Create a person record.
   *
   * @param user
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} phone
   * @param {string} email
   * @returns {Promise<any>}
   */
  createPerson(
    user: any,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    password: string
  ): Observable<any> {
    const tzOffset = DateTime.now().offset,
      person = {
        user_id: user.id,
        first_name: firstName,
        last_name: lastName,
        phone: phone ? "+1" + phone.replace("+1", "").replace(/\D/g, "") : null,
        email,
        password,
        tzOffset,
      };

    // send the request
    return this.http.post("/person/create", person);
  }

  /**
   * Check if a token is
   * @returns {boolean}
   */
  tokenExpired() {
    // Check if the token is expired
    const token = this.getToken(),
      payload = token.split(".")[1] || "";
    if (!payload) {
      return true;
    }

    return JSON.parse(atob(payload)).exp * 1000 <= new Date().getTime();
  }

  /**
   * Grab the user token.
   */
  getToken() {
    return localStorage.getItem("token");
  }

  /**
   * Get a users id.
   *
   * @returns {any}
   */
  get userId() {
    try {
      const user = JSON.parse(atob(localStorage.getItem("user")));

      if (!user) {
        this.logout();
      }

      return user?.id;
    } catch (e) {
      return null;
    }
  }

  /**
   * Get a users id.
   *
   * @returns {any}
   */
  getTempTokenId(token: any) {
    const data = JSON.parse(token);

    if (!data) {
      this.logout();
    }

    return data;
  }

  /**
   * Get a Single Person with User loaded in object
   * req: userId:number
   * res {}
   */
  getCurrentUser(): Observable<User> {
    /*
     * Query Object to return child object on normalize tables
     * endpoint works for all tables with id references
     * Also works for nested objects
     *
     * */
    return this.http.get(`/person/current`).pipe(
      map((res) => {
        return new User(res.data[0] as User);
      })
    );
  }

  /**
   * Update the user object.
   *
   * @param {User} user
   * @returns {Promise<User>}
   */
  updateUser(user: User, asUser?: number): Promise<AuthResponse | void> {
    if (asUser) user.reset = false;
    return this.http
      .post(`/person/update-user-person${asUser ? "/" + asUser : ""}`, user)
      .toPromise()
      .then((authRes) => {
        if (authRes.token) {
          this.userAndToken = authRes;
        }
        return authRes as AuthResponse;
      });
  }

  /**
   * Update a user password.
   *
   * @param {ChangePasswordRequest} newPass
   * @returns {Promise<AuthResponse>}
   */
  updateUserPassword(newPass: ChangePasswordRequest): Promise<AuthResponse> {
    return this.http
      .post(`/user/id/${newPass["id"]}/change-password`, newPass)
      .toPromise()
      .then((authRes) => {
        const asUser =
          this.route.snapshot?.children?.[0]?.params?.as_user ||
          this.route.snapshot?.params?.as_user;

        if (!asUser) {
          authRes = authRes.data;
          authRes.token = authRes.hash;
          authRes.user = this.user;
          this.userAndToken = authRes;
        } 
        
        return authRes as AuthResponse;
      });
  }

  /**
   * Changes a users password without the need for old password
   *
   * @param {ChangePasswordRequest} newPass
   * @returns {Promise<AuthResponse>}
   */
  changeUserPassword(newPass: ChangePasswordRequest): Promise<AuthResponse> {
    return this.http
      .post(`/person/change-password`, newPass)
      .toPromise()
      .then((authRes) => {
        return authRes as AuthResponse;
      });
  }

  /**
   * Resets user password.
   *
   * @param {ResetPasswordRequest} newPass
   * @returns {Promise<AuthResponse>}
   */
  recoverPassword(newPass: ResetPasswordRequest): Promise<AuthResponse | void> {
    return this.http.post(`/user/recover-password`, newPass).toPromise();
  }

  /**
   * Update a person object.
   *
   * @param {Person} person
   * @returns {Promise<Person>}
   */
  updatePerson(person: Person): Promise<Person | void> {
    return this.http
      .put(`/person/id/${person.id}`, person)
      .toPromise()
      .then((res) => {
        return res.data as Person;
      });
  }

  /**
   * Handle errors when they occur.
   *
   * @param err
   */
  // Retrieve error object from response and throw that object
  handleError(err: any) {
    let errRet = err;
    if (err._body) {
      try {
        errRet = JSON.parse(err._body);
      } catch (ex) {
        console.error("user.service.error", err);
        errRet = { statusCode: err.status, errorMessage: err._body };
      }
    }

    throw errRet;
  }

  /**
   * Generate a random password for admin users.
   *
   * @param len
   * @returns {string}
   */
  passwordGenerator(len) {
    const alphabet =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUFVWYZ0123456789!@#$%&*";
    let pass = "",
      i = 0;

    while (i < len) {
      pass += alphabet[Math.floor(Math.random() * alphabet.length)];
      i++;
    }

    return pass;
  }

  /**
   * Trigger a reset password for the user.
   */
  resetPassword = (email) => {
    if (email) {
      this.magic
        .sendForgotPasswordEmail(email)
        .then((res: any) => {
          this.notify.toast({
            id: "user-password-reset",
            msg: `An email has been sent to ${email} for them to reset their password`,
            type: "success",
            timeout: 10000,
            update: true,
          });
        })
        .catch((err: any) => {
          console.log("Error sending forgot password email: ", err);
        });
    }
  };

  confirmResetPassword(record: string) {
    const initialState: Partial<ConfirmComponent> = {
      confirmTitle: `Send Password Reset`,
      confirmMsg:
        "Are you sure you want to begin the reset password process for this user?",
      ok: "Send Password Reset",
      cancel: "Cancel Password Reset",
      record,
      cb: this.resetPassword,
    };

    this.modalService.show(ConfirmComponent, { initialState });
  }
}
